var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"order-create__section-label section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'consolidations_shippingPartner',
        'consolidations_shippingPartnerText',
        ])}}}),_vm._v(" "+_vm._s(_vm.$t('consolidations_shippingPartner.localization_value.value'))+" "),_c('v-popover',{staticClass:"site-tooltip",attrs:{"disabled":!_vm.tooltipAddProductActive,"offset":"5","placement":"top","trigger":"hover"}},[_c('TooltipBtn'),_c('template',{slot:"popover"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t('consolidations_shippingPartner.localization_value.value')))])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('consolidations_shippingPartnerText.localization_value.value'))+" ")])])],2)],1),_c('div',{staticClass:"order-create__row custom-row"},[_c('div',{staticClass:"order-create__col custom-col"},[_c('div',{staticClass:"order-create__toggle-block toggle-block small-size"},[_c('div',{staticClass:"toggle-block__type"},[_c('div',{staticClass:"toggle-block__type-list"},[_vm._l((_vm.destinationData),function(item,index){return [_c('div',{key:index,staticClass:"toggle-block__type-item mb-3"},[_c('RadioDefault',{class:{
                        'order-create__head-title destination': _vm.adminEdit
                      },attrs:{"label":item.currentTranslate.name,"value":_vm.checkActiveDestination(item),"disabled":(_vm.isFromYourCity && _vm.consolidation.getLabelFileUUID() !== '') ||
                          (_vm.CONSOLIDATION_TYPE_DHL_FROM_YOUR_CITY.id === item.id && (
                              (!_vm.isUseAlternativeContact && _vm.isUkrainian) || _vm.isUserAlternativeContactUkraine)),"name":"'destination'"},on:{"input":function($event){return _vm.changeDestination(item)}}},[_c('template',{slot:"card"},[(_vm.isEUProduct && _vm.consolidation.getOrderWarehouse() && _vm.consolidation.getOrderWarehouse().name)?_c('span',{class:{
                              'cambridge-style' : _vm.consolidation.getOrderWarehouse().name === 'Cambridge' && _vm.adminEdit,
                              'blaine-style' : _vm.consolidation.getOrderWarehouse().name === 'Blaine' && _vm.adminEdit,
                              'lodz-style' : _vm.consolidation.getOrderWarehouse().name === 'Lodz' && _vm.adminEdit,
                            }},[_vm._v(" ("+_vm._s(_vm.consolidation.getOrderWarehouse().name)+") ")]):_vm._e(),(_vm.isDPD && _vm.adminEdit)?_c('span',{class:{
                              'blaine-style' : _vm.isDPD,
                            }},[_vm._v(" (Wroclaw) ")]):_vm._e()])],2)],1)]})],2)]),(_vm.getWarehouses && _vm.getWarehouses.length > 0 && _vm.loadingFedexWarehouses && !_vm.adminEdit && !_vm.isFromYourCity && !_vm.isUserHasEasyOrder)?_c('div',{staticClass:"toggle-block__details",class:{'ui-no-valid': _vm.consolidation.validation.warehouse}},[_c('div',{staticClass:"toggle-block__details-body",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
              'common_city',
              ])}}}),_c('div',{staticClass:"section-label mb-2"},[_vm._v(" "+_vm._s(_vm.$t('common_city.localization_value.value'))+" ")]),_c('div',{staticClass:"custom-row"},[_vm._l((_vm.getWarehouses),function(warehouse,indexWarehouse){return [_c('div',{key:indexWarehouse,staticClass:"custom-col mb-2"},[_c('RadioDefault',{attrs:{"label":warehouse.currentTranslate.name,"value":_vm.checkActiveWarehouse(warehouse),"errorTxt":_vm.$t(`${_vm.consolidation.validationTranslate.warehouse}.localization_value.value`),"error":_vm.consolidation.validation.warehouse,"name":"'warehouse'"},on:{"input":function($event){return _vm.changeWarehouse(warehouse)}}})],1)]})],2)])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }