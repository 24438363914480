import { render, staticRenderFns } from "./UserInfo.vue?vue&type=template&id=394ff381&scoped=true"
import script from "./UserInfo.vue?vue&type=script&lang=js"
export * from "./UserInfo.vue?vue&type=script&lang=js"
import style0 from "./UserInfo.vue?vue&type=style&index=0&id=394ff381&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394ff381",
  null
  
)

export default component.exports