<template>
  <div class="order-create__section">


    <ProductsTable
        v-if="!isEUProduct && !isUserHasEasyOrder"
        :consolidation="consolidation"
    />

    <AddProductFromWarehouse
        v-if="!isDPD"
        :consolidation="consolidation"
        :user="consolidation.data.User"
        :orderUserId="consolidation.data.User.userId"
        :products="consolidation.data.productsForCellArray"
    />

<!--    <div class="order-create__total">-->
<!--      {{$t('common_TotalShippingCost.localization_value.value')}} <span> ${{consolidation.data.shippingCost}} </span>-->
<!--    </div>-->

  </div>
</template>

<script>
import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
import ProductsTable from "../../chunks/ProductsTable/ProductsTable";
import AddProductFromWarehouse from "../../chunks/AddProductFromWarehouse/AddProductFromWarehouse";
import {consolidationMixinHelper} from "../../../../../../../mixins/consolidationMixins/consolidationMixinHelper";

export default {
  name: "StepC",
  components: {
    AddProductFromWarehouse,
    ProductsTable,
  },

  mixins: [
    mixinDetictingMobile,
    consolidationMixinHelper
  ],

  props: {
    consolidation: Object,
  },

  data(){
    return {

    }
  },

  methods: {
    // getProformTypeName(val) {
    //   let nameProp = ''
    //   Object.keys(val).some(typeName => {
    //     //get string before "_" character
    //     nameProp = typeName.split('_')[0]
    //     return true
    //   })
    //
    //   return nameProp
    // }
  },
}
</script>

<style scoped>
  .table-row--input{
    max-width: 80px;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child{
    padding-right: 0;
  }

  .table-manage-list .manager-btn{
    width: 80px;
  }

</style>