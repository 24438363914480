<template>
  <div class="order-create__section">

    <OrdersFBMBlock
        v-if="!isTNT && !isEUProduct && !isUserHasEasyOrder"
        :consolidation="consolidation"
        :orderUserId="consolidation.data.User.userId"
    />

    <DimensionsBlock
        v-if="!isTNT"
        :consolidation="consolidation"
    />

<!--        v-if="(isFedex || isDHL_USA) && isSkladLodz"-->
    <PromoCode
        v-if="!isFromYourCity && showPromoFields"
        :consolidation="consolidation"
    />

    <CommentBlock
        v-if="!isTNT"
        :consolidation="consolidation"
    />
  </div>
</template>

<script>
import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
import {consolidationMixin} from "../../../../../../../mixins/consolidationMixins/consolidationMixin";
import DimensionsBlock from "../../chunks/DimensionsBlock/DimensionsBlock";
import CommentBlock from "../../chunks/CommentBlock/CommentBlock";
import OrdersFBMBlock from "../../chunks/OrdersFBMBlock/OrdersFBMBlock";
import PromoCode from "@/components/modules/ConsolidationModule/components/chunks/chunks/PromoCode/PromoCode";

export default {
  name: "StepB",

  components: {
    PromoCode,
    OrdersFBMBlock,
    CommentBlock,
    DimensionsBlock,
  },

  props: {
    consolidation: Object,
    showPromoFields: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [
      mixinDetictingMobile,
      consolidationMixin
  ],
}
</script>

<style lang="scss" scoped>

</style>